.ViewManySearchTableModal{
	background-color: white;
	height:100%;
	overflow:auto;
}


.SearchButton{
	border:none;
	width: fit-content;
	padding:10px;
	border-radius:5px;
	text-align:center;
	margin:auto;
	background-color:#F9F9FF;
	color:black;
	box-sizing: border-box;
	display:inline-block;
	box-shadow:-2px -2px 2px inset #0004;
	
}

.SearchButton:hover{
	cursor:pointer;
	background-color:white;
	box-sizing: border-box;
	box-shadow:2px 2px 2px #0004;
	transition:0.1s ease;
}

.ModalHidden{
	visibility: none;
	display: none;
}