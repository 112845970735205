.Table {
	width:100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 25px;
	border-radius: 5px;
	border-style:hidden;
	background-color: #EEFFFF;
	border-collapse: collapse;
	font-size:16px;
	box-sizing:border-box;
	text-align: left;
	white-space: nowrap;
	overflow-x:auto;
}
.TableContainer {
	overflow: auto;
	background-color: #EEFFFF;
	width:90%;
	max-height:40%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 25px;
	margin-top: 25px;
	border-radius: 5px;
	box-shadow:3px 3px 3px #0004;
}

.TableCell {
	border: 0px solid #0004;
	padding-left: 10px;
	padding-right: 10px;
	max-width:300px;
	overflow:hidden;
}
.TableHeader {
	background-color:#BBDDDD;
	font-weight:bold;
	border-bottom: 1px solid black;
}

.TableRow {
	border-bottom: 1px solid #DEE;
}

.TableBody{
	overflow-y: auto;
}

.TableRow:hover {
	background-color: #DDFFFF;
}

.TableRowSelected{
	background-color: #AAF;
}
.TableRowSelected:hover{
	background-color: #AAF;
}

.TableCellContent{
	overflow:hidden;
	height:20px;
	font-size:12px;
}

.TableFormInputWrapper_undefined > input{
	background-color:#FFF6;
	border: 1px solid #FFF9;
	font-weight:700;
}

.TableFormInputWrapper_undefined > input:disabled{
	background-color:#FFF0;
	border: 1px solid #FFF0;
	box-shadow: 0px 0px 0px;
	font-weight:700;
}