.Table {
	width:100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 25px;
	border-radius: 5px;
	border-style:hidden;
	table-layout:fixed;
	background-color: #EEFFFF;
	border-collapse: collapse;
	font-size:16px;
	box-sizing:border-box;
	text-align: left;
	white-space: nowrap;
	overflow-x:scroll;
}
.TableContainer {
	overflow: auto;
	background-color: #EEFFFF;
	width:90%;
	max-height:40%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 25px;
	margin-top: 25px;
	border-radius: 5px;
	box-shadow:3px 3px 3px #0004;
}

.TableCell {
	border: 0px solid #0004;
	padding-left: 10px;
	padding-right: 10px;
	max-width:500px;
	overflow:hidden;
	text-overflow: ellipsis;
	box-sizing:border-box;
}
.TableHeader {
	background-color:#BBDDDD;
	font-weight:bold;
	width:100%;
	border-bottom: 1px solid black;
	box-sizing:border-box;
}

.TableHeaderCell {
	border: 0px solid #0004;
	padding-left: 10px;
	padding-right: 10px;
	overflow:hidden;
	text-overflow: ellipsis;
	position:sticky;
	box-sizing:border-box;
}

.TableHeaderBorder{
	width:8px;
	height:1.5em;
	box-sizing: border-box;
	background-color:#0000;
	cursor:ew-resize;
	position:absolute;
	right:0;
}

.TableHeaderBorderLine{
	width:2px;
	height:1.5em;
	margin:auto;
	box-sizing: border-box;
	background-color:#0002;
	position:absolute;
	left:3px;
}

.TableRow {
	border-bottom: 1px solid #DEE;
}

.TableBody{
	overflow-y: auto;
	overflow-x:scroll;
}

.TableRow:hover {
	background-color: #DDFFFF;
}

.TableRowSelected{
	background-color: #AAF;
}
.TableRowSelected:hover{
	background-color: #AAF;
}

.TableCellContent{
	overflow:hidden;
	height:20px;
	font-size:12px;
}