.Menu{
	z-index: 99;
	position:fixed;
	width:250px;
	max-height:80%;
	box-shadow: 5px 5px 5px #0004;
	box-sizing: border-box;
	display: none;
	visibility:hidden;
	overflow:auto;
}

.MenuVisible{
	display: block;
	visibility: visible;
}

.MenuItem{
	background-color: #333;
	border-top: 1px solid #393939;
	border-bottom: 1px solid #393939;
	overflow:hidden;
	padding: 10px 15px;
	box-sizing: border-box;
}

.MenuItem:hover{
	background-color: #444;
	border-top: 1px solid #777;
	border-bottom: 1px solid #777;
	cursor: pointer;
}

.MenuItemLabel{
	font-size: 20px;
	color: white;
	padding:5px;
	margin-left:15px;
	float: left;
}

.MenuItemIcon{
	font-size: 24px;
	color: black;
	float:left;
	text-align: center;
	position:relative;
}


