.Container {
	//overflow: auto;
	//height: auto;
	//width: auto;
	//background-color: #FFFFFF;
	//box-sizing: border-box;
}

.AppContainer {
	height: 100vh;
	width: 100vw;
	padding: 0;
	margin: 0;
	overflow: clip;
	box-sizing: border-box;
}

.BoxContainer {
	overflow: auto;
	background-color: #00FFFF;
	border: 1px solid black;
	box-sizing: border-box;
}

.TopNavContainer {
	height: 60px;
	width:100vw;
	overflow: hidden;
	box-sizing: border-box;
	box-shadow: 3px 3px 10px #0004;
	top:0px;
	position:fixed;
}

.SubNavContainer {
	height: 40px;
	width:100vw;
	overflow: hidden;
	box-sizing: border-box;
	box-shadow: 3px 3px 10px #0004;
	top:60px;
	position:fixed;
}

.PageContainer {
	height: calc(100vh - 100px);
	width: 100vw;
	overflow: auto;
	box-sizing: border-box;
	position:fixed;
	top:60px;
}

.FooterContainer{
	height: 40px;
	width: 100%;
	position: fixed;
	bottom: 0px;
	box-sizing: border-box;
	overflow: hidden;
}