.LandingPage{
	height:100%;
	width:100%;
	background-color:white;
	
	text-content:center;
}

.LandingPageLogo{
	position:relative;
	top:30%;
	margin-left:auto;
	margin-right:auto;
	display:block;
	
}