.Banner {
	margin:auto;
	padding-left:30px;
	padding-bottom:5px;
	width: 100%;
	font-size: 48px;
	font-weight:700;
	background-color: #335; 
	color:white;
	border-radius:0px 0px 20px 0px;
	box-sizing: border-box;
	box-shadow: 5px 5px 5px #0004;
	font-family:montserrat;
	overflow:hidden;
}

.BannerIcon {
	font-size:10px;
	margin-right:30px;
}