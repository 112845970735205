.SignInModal{
	margin:auto;
	position:absolute;
	width:50%;
	height:fit-content;
	top:30%;
	left:25%;
	border: 2px solid black;
	border-radius:10px;
	box-shadow: 10px 10px 10px #0004;
	overflow:hidden;
	background-color: #000;
	border:none;
	overflow:auto;
}

.SignInFormSection{
	display: grid;
	grid: auto / auto auto;
}

.SignInForm {
	border-top: 1px solid #000;
	border-left: 1px solid #000;
	background-color:#E6EEEE;
	position:relative;
	margin:auto;
	display: grid;
	grid: auto / auto;
	border-radius:10px;
	box-sizing:border-box;
	box-shadow:5px 5px 15px #0004;
	padding:10px 20px 10px 20px;
	overflow:hidden;
}

.SignInFormButton{
	border:none;
	width: fit-content;
	padding:10px;
	border-radius:5px;
	text-align:center;
	margin-left:auto;
	margin-top:20px;
	float:right;
	background-color:#F9F9FF;
	color:black;
	box-sizing: border-box;
	display:inline-block;
	box-shadow:-2px -2px 2px inset #0004;
	
}

.SignInFormButton:hover{
	cursor:pointer;
	background-color:white;
	box-sizing: border-box;
	box-shadow:2px 2px 2px #0004;
	transition:0.1s ease;
}

.ModalHidden{
	visibility: none;
	display: none;
}