.Form{
	border-top: 1px solid #CCC;
	border-left: 1px solid #CCC;
	background-color:#F0FFFF;
	width:100%;
	height: calc(100% - 50px);
	margin:auto;
	border-radius:10px;
	box-sizing:border-box;
	box-shadow:5px 5px 15px #0004;
	margin-top:20px;
	padding-bottom:20px;
	overflow:auto;
	font-family:Arial;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0px 5px 5px 0px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0px 5px 5px 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.FormSection {
	margin:auto;
	display: grid;
	grid: auto / auto;
	border-radius:10px;
	box-sizing:border-box;
	margin-top:20px;
	padding-left:20px;
	overflow:auto;
}


.SearchFormSection{
	display: grid;
	grid: auto / auto auto;
	grid-gap:0px 40px;
	
}

.SearchForm {
	border-top: 1px solid #CCC;
	border-left: 1px solid #CCC;
	background-color:#F0FFFF;
	width:90%;
	position:relative;
	margin:auto;
	display: grid;
	grid: auto / auto;
	border-radius:10px;
	box-sizing:border-box;
	box-shadow:5px 5px 15px #0004;
	margin-top:20px;
	padding:10px 20px 10px 20px;
	overflow:hidden;
}

.TableSection {
	background-color:#F0FFFF;
	box-sizing:border-box;
	margin-top:20px;
	overflow:hidden;
}

.FormButtons {
	width:100%;
	margin:auto;
	border-radius:10px;
	box-sizing:border-box;
}

.FormCollapsed {
	display: none;
}

.FormSectionCollapsed{
	display: none;
}

.InputField{
	display:grid;
	grid: auto / 50% 50%;
	margin-top:10px;
}

.FormTitle{
	font-size:24px;
	text-decoration:underline;
	margin-bottom:10px;
}

.FormTitle > span{
	margin-left: 10px;
}

.FormLabel{
	color:black;
	font-size:1rem;
	overflow: hidden;
}

.InputBox {
	font-size:14px;
	border: 2px solid black;
	border-radius: 5px;
	padding: 3px;
	box-shadow: -2px -2px 2px inset #0004;
	background-color:#FFF;
}

.InputBox:disabled {
	font-size:14px;
	border: 2px solid black;
	border-radius: 5px;
	padding: 3px;
	box-shadow: -2px -2px 2px inset #0004;
	background-color:#EEE;
}

.InputWrapper_undefined{
	margin:auto;
	font-size:16px;
	font-weight:400;
	width:180px;
}
.InputWrapper_password{
	margin:auto;
	font-size:16px;
	font-weight:400;
	width:180px;
}
.InputWrapper_number{
	margin:auto;
	font-size:16px;
	font-weight:400;
	width:180px;
}

.InputWrapper_select{
	margin:auto;
	font-size:16px;
	font-weight:400;
	width:180px;
}

.InputWrapper_checkbox{
	margin:auto;
	font-size:16px;
	font-weight:400;
}
.InputWrapper_file{
	margin:auto;
	font-weight:400;
	border: 1px solid black;
	width:25px;
	height:20px;
	font-size:14px;
	border: 2px solid black;
	border-radius: 5px;
	padding: 3px;
	box-shadow: -2px -2px 2px inset #0004;
	background-color:#FFF;
}
.MultiSearchBox{
	margin-left:auto;
	font-weight:400;
	border: 1px solid black;
	width:300px;
	height:100px;
	font-size:14px;
	padding:5px 10px;
	border: 2px solid black;
	border-radius: 5px;
	box-shadow: -2px -2px 2px inset #0004;
	background-color:#FFF;
	overflow:auto;
}

.MultiSearchBoxContainer{
	width: fit-content;
	height:fit-content;
	margin-left:auto;
}

.MultiSearchBoxToolbar{
	border: 1px solid black;
	border-radius:10px;
	background-color:lightgray;
	width: fit-content;
	margin-top:15px;
	font-size:14px;
	margin-left:auto;
}
.MultiSearchToolbarButton{
	cursor:pointer;
	background-color:black;
	border:1px solid white;
	padding:5px;
	margin:2px;
	border-radius:10px;
	box-shadow:2px 2px 2px #0004;
}

.MultiSearchToolbarButton:disabled{
	cursor:not-allowed;
	background-color:lightgray;
	border:1px solid white;
	padding:5px;
	margin:2px;
	border-radius:10px;
	box-shadow:0px 0px 0px #0004;
}

.MultiSearchBoxItem{
	width: 100%;
	height:fit-content;
}
.MultiSearchBoxItem:hover{
	background-color:lightblue;
	width: 100%;
}

.MultiSearchBoxItemSelected{
	background-color:lightblue;
	width: 100%;
	height:fit-content;
}

.InputBox[type=file] {
	display:none;
	border:1px solid black;
}
select.InputBox {
	width:100%;
}
input.InputBox:not([type=checkbox]){
	width:100%;
}

.InputBox:focus {
	box-shadow: 2px 2px 2px #0004;
	outline:none;
}