.IconButton {
	height: 32px;
	width: 36px;
	text-align: center;
	background-color: #111111;
	border: 1px solid #FFFFFF00;
	border-radius: 5px;
	padding-top: 4px;
}
.IconButton:hover{
	height: 32px;
	width: 36px;
	text-align: center;
	background-color: #334;
	border: 1px solid #FFFFFFFF;
	border-radius: 5px;
	padding-top: 4px;
	text-align: center;
	transition: 0.5s;
	cursor:pointer;
}

.TextButton{
	border:none;
	width: fit-content;
	padding:10px;
	border-radius:5px;
	text-align:center;
	margin-left:auto;
	margin-top:20px;
	background-color:#F9F9FF;
	color:black;
	box-sizing: border-box;
	display:inline-block;
	box-shadow:-2px -2px 2px inset #0004;
	float:right;
}

.TextButton:hover{
	cursor:default;
	background-color:white;
	box-sizing: border-box;
	box-shadow:2px 2px 2px #0004;
	transition:0.1s ease;
}
