.Toolbar{
	border: 1px solid black;
	border-radius:10px;
	background-color:lightgray;
	width: fit-content;
	margin-top:15px;
	font-size:14px;
	margin-left:5%;
	box-sizing:border-box;
}

.ToolbarButton{
	cursor:pointer;
	background-color:black;
	border:1px solid white;
	padding:5px;
	margin:2px;
	border-radius:10px;
	box-shadow:2px 2px 2px #0004;
}

.ToolbarButton:disabled{
	cursor:not-allowed;
	background-color:lightgray;
	border:1px solid white;
	padding:5px;
	margin:2px;
	border-radius:10px;
	box-shadow:0px 0px 0px #0004;
}