.Collapsible{
	position: fixed;
	background-color: #DDD;
	overflow-x: hidden;
	box-sizing: border-box;
	transition: 0.5s;
}

.CollapsibleTab{
	
}