.ProjectsPage{
	background-color: white;
	height:100%;
	overflow:auto;
}

.FlowchartCanvas{
	width: 90%;
	height: 90%;
	border: 2px solid black;
	margin: auto;
	margin-bottom:5%;
	overflow:auto;
}

.FlowchartAction{
	width:100px;
	height:100px;
	margin: 10px;
	background-color: red;
}

.FlowchartDecision{
	width: 0;
	height: 0;
	border: 50px solid transparent;
	border-bottom-color: red;
	position: relative;
	top: -50px;
}
.FlowchartDecision:after{
	content: '';
	position: absolute;
	left: -50px;
	top: 50px;
	width: 0;
	height: 0;
	border: 50px solid transparent;
	border-top-color: red;
}

 #diamond {
      width: 0;
      height: 0;
      border: 50px solid transparent;
      border-bottom-color: red;
      position: relative;
      top: -50px;
    }
    #diamond:after {
      content: '';
      position: absolute;
      left: -50px;
      top: 50px;
      width: 0;
      height: 0;
      border: 50px solid transparent;
      border-top-color: red;
    }

.FlowchartTerminal{
	width:100px;
	height:50px;
	margin: 10px;
	background-color: green;
	border-radius:30px;
	color:white;
	text-align:center;
	vertical-align:center;
}