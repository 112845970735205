.Modal{
	margin:auto;
	position:absolute;
	width:70%;
	max-height:80%;
	top:10%;
	left:15%;
	border: 2px solid black;
	border-radius:10px;
	box-shadow: 10px 10px 10px #0004;
	overflow:hidden;
}

.ModalOverlay{
	width:100%;
	height:100%;
	top:0px;
	left:0px;
	position:absolute;
	background-color:#0006;
	overflow:hidden;
}