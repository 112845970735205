.Treeview {
	width:90%;
	margin-top:25px;
	margin-left: auto;
	margin-right: auto;
	padding:20px;
	border: 1px solid black;
	border-radius: 5px;
	background-color: #DDFFFF;
	border-collapse: collapse;
	box-shadow:3px 3px 3px #0004;
	font-size:16px;
	box-sizing:border-box;
	text-align: left;
	height: calc(100% - 50px);
	overflow:auto;
}
.TreeviewBranch{
	width:100%;
	height:fit-content;
}

.TreeviewBranchHeader{
	background-color:#FFF;
	border-radius:15px 0px 0px 15px;
	border-bottom: 1px solid #AAA;
	border-left: 1px solid #AAA;
}

.TreeviewBranchSelected{
	background-color: #AAF;
	border-radius:15px 0px 0px 15px;
	border-bottom: 1px solid #AAA;
	border-left: 1px solid #AAA;
}

.TreeviewBranchHeader:hover{
	background-color:#EEFFFF;
}
.TreeviewBranchSelected:hover{
	background-color: #AAF;
	border-radius:15px 0px 0px 15px;
	border-bottom: 1px solid #AAA;
	border-left: 1px solid #AAA;
}

.TreeviewBranchLabel{
	display: inline-block;
	padding-top:10px;
	padding-bottom:10px;
	padding-left:25px;
}

.TreeviewExpandIcon{
	display: inline-block;
	float:right;
	padding-left: 15px;
	padding-bottom:10px;
	padding-top:10px;
	width:50px;
	box-sizing:border-box;
	border-left:1px solid #0001;
}

.TreeviewChildren{
	padding-left:20px;
	border-left: 1px solid black;
	border-right: 10px solid #CCC;

	opacity:0;
	display:none;
	overflow:hidden;
}

.TreeviewChildrenExpanded{
	display:block;
	opacity:1;
	transition:opacity 0.5s ease;
}