.TopNav{
	height: 100%;
	width: 100%;
	padding: 6px;
	background-color: #111;
	box-sizing: border-box;
	overflow: hidden;
}

.TopNavItem{
	height: 32px;
	width: 36px;
	text-align: center;
	background-color: #111111;
	border: 1px solid #FFFFFF00;
	border-radius: 5px;
	margin: 5px;
	padding-top: 4px;
}

.TopNavItem:hover{
	background-color: #334;
	border: 1px solid #FFFFFFFF;
	border-radius: 5px;
	padding-top: 4px;
	text-align: center;
	transition: 0.5s;
	cursor:pointer;
	margin: 5px;
}

.TopNavLogo{
	margin: 0px 2px 2px 12px;
	padding: 7px;
	float: left;
	border: 1px solid #FFFFFF00;
	border-radius: 5px;
	box-sizing: border-box;
}
.TopNavLogo:hover{
	background-color: #111;
	border: 1px solid #FFFFFF22;
	border-radius: 10px;
	box-sizing: border-box;
	cursor: pointer;
}